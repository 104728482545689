
.dog-certificates {
  .dog-certificate {
    border-bottom: 1px solid $gray-base;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.dog-related-name {

  cursor: pointer;

  &:hover {
    text-decoration: line-through;

    &:after {
      margin-left: 10px;
      color: red;
      content: "\00d7";
    }
  }

}



.caption {
  transition: .5s;
  width: calc(100% - 8px);
  bottom: 4px;
  position: absolute;
  background-color: rgba(0, 0, 0, .65);
  color: rgba(255, 255, 255, 1);
  display: none;
}

.thumbnail:hover .caption {
  transition: .5s;
  display: block;
}

.caption span {
  color: #fff;
  -webkit-font-smoothing: antialiased;
}