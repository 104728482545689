
* {
  margin: 0;
  padding: 0;
}

$orange: rgba(211, 84, 0, .5);
$orange-light: rgba(211, 84, 0, .15);

$blue: rgba(52, 152, 219, .5);
$blue-light: rgba(52, 152, 219, .15);

$purple: rgba(142, 68, 173, 1.0);
$purple-light: rgba(142, 68, 173, .5);


.branch-sire{
  background-color: $blue-light;
  border-color: $blue;
}

.branch-dam {
  background-color: $orange-light;
  border-color: $orange;
}

.tree {

  background-color: #FFFFFF;

  ul {
    padding-top: 20px;
    position: relative;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    white-space: nowrap;
  }
  li {
//    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    display:inline-block;
    vertical-align: top;
    white-space: normal;
    background-color: #FFF;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 50%;
      border-top: 1px solid #ccc;
      width: 50%;
      height: 20px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 50%;
      border-top: 1px solid #ccc;
      width: 50%;
      height: 20px;
      right: auto;
      left: 50%;
      border-left: 1px solid #ccc;
    }
    &:only-child {
      &::after, &::before {
        display: none;
      }
      padding-top: 0;
    }
    &:first-child::before {
      border: 0 none;
    }
    &:last-child {
      &::after {
        border: 0 none;
      }
      &::before {
        border-right: 1px solid #ccc;
        border-radius: 0 5px 0 0;
        -webkit-border-radius: 0 5px 0 0;
        -moz-border-radius: 0 5px 0 0;
      }
    }
    &:first-child::after {
      border-radius: 5px 0 0 0;
      -webkit-border-radius: 5px 0 0 0;
      -moz-border-radius: 5px 0 0 0;
    }
  }
  ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
  }
  li a, {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    &.branch-sire{
      @extend .branch-sire;
    }

    &.branch-dam {
      @extend .branch-dam;
    }

    &:hover {
      background-color: $purple-light;
      border: 1px solid $purple;
      color: #000;
      + ul {
        li {
          a {
            background-color: $purple-light;
            border: 1px solid $purple;
            color: #000;
          }
          &::after, &::before {
            border-color: #94a0b4;
          }
        }
        &::before, ul::before {
          border-color: #94a0b4;
        }
      }
    }
  }
}
